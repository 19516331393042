import Image from "next/image";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import Animate from "../Common/AnimateOnScroll";
import dynamic from "next/dynamic";
import { Autoplay } from "swiper";
import Typewriter from "typewriter-effect";
import Link from "next/link";

const ReviewCard = dynamic(() => import("./ReviewCard"));
const SliderButtons = dynamic(() => import("../Common/SliderButtons"));

const Reviews = () => {
  const reviews = [
    {
      id: 1,
      name: "Djoreally",
      country: "United States",
      img: "",
      review:
        "380 characters is not enough. there is so much I could say about this seller and my experience. it was honestly in the TOP 3 experiences I've had on fiverr. communication 💯 Services as described 💯 would I recommend 💯💯💯💯💯💯 YES",
    },
    {
      id: 2,
      name: "Antenehgm",
      country: "Canada",
      img: "",
      review:
        "Zilly successfully delivered a complex project with high quality in tight timeline. She is exceptional in so many respects: communication, technical skill, patience ,commitment and problem solving, and above all her skill in design and coding. I enjoyed working with her throughout the project and recommend her for medium to large projects",
    },
    {
      id: 3,
      name: "Veggellisan",
      country: "Greece",
      img: "",
      review:
        "I want to express my appreciation for her exceptional work. Her skills, dedication, and clear communication throughout this project have exceeded my expectations. Her technical expertise and ability to translate complex concepts into user-friendly solutions have been instrumental in creating a platform that goes beyond what I imagined. I am deeply grateful for her commitment to bring in life my dream webpage.",
    },
    {
      id: 4,
      name: "Opera",
      country: "UK",
      img: "",
      review:
        "Zilly has provided an awesome service, she is knowledgeable, proactive and has bias for action which helped me a lot when I couldn't provide enough guidance. In the end, she has delivered a nice web app dashboard, beyond my expectations with very minor reviews and corrections.",
    },
    {
      id: 5,
      name: "Tauseef",
      country: "Pakistan",
      img: "",
      review:
        "I have tried and tested dozens of developers but Zilly is indeed one of the finest and most caring developer, who actually takes care of your business project like her own ! There were a few deficiencies in initial delivery ( happens with everyone ) but i liked the way she tried best to improve the delivery and fix the bugs. This is what really makes her different from others who just try to get rid of ur project without caring abt your level of satisfaction. Im very satisfied so far and i hope things wont change in future rather i'd be getting even better response and care from this team. All the best Team Zilly !",
    },
    {
      id: 6,
      name: "Shabeer",
      country: "Canada",
      img: "",
      review:
        "She did a great job and was in constant communication to make sure the project all the acceptance criteria were met and delivered. Regular demos were also scheduled and if I wanted anything changed, we would discuss it to make sure it was the right track for the project. We have already scheduled Version 2 and other versions of this project with the same seller. Amazing service. Thank you and I look forward to working more with you :)",
    },
    // {
    //   name: "Yasir",
    //   country: "USA",
    //   review:
    //     "Amazing work! Great quality great communication and fast turn around! I do recommend!!",
    // },
  ];
  return (
    <div className=" flex flex-col px-8 xl:px-0 py-5 xl:mt-10 relative">
      <div className="flex flex-col max-w-screen-xl mx-auto">
        <Animate.ScaleIn>
          <div className="flex flex-col space-y-2 xl:space-y-4">
            <span
              className={`fontNew text-xl xl:text-3xl font-bold text-secondary`}
            >
              Top Testimonials
            </span>
            <div className="flex space-x-2 items-center w-full">
              <p
                className={`fontNew font-bold text-xl xl:text-5xl text-primary`}
              >
                What Our
              </p>
              <div
                className={`fontNew font-bold text-xl xl:text-5xl text-secondary`}
              >
                <Typewriter
                  options={{
                    strings: ["Clients Say "],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
              <p
                className={`fontNew font-bold text-xl xl:text-5xl text-primary`}
              >
                About Us
              </p>
            </div>
            <span className="text-base font-normal max-w-lg text-justify">
              We value client satisfaction and strive to exceed their
              expectations with our services. Here are some reviews and feedback
              we have received from our clients
            </span>
          </div>
        </Animate.ScaleIn>
        <div className="max-w-[300px] md:max-w-screen-xl mx-auto">
          <Swiper
            freeMode={true}
            grabCursor={true}
            autoplay
            className="mySwiper"
            modules={[Autoplay]}
            breakpoints={{
              1180: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
            style={{
              width: "100%",
              paddingTop: "4rem",
              paddingBottom: "4rem",
            }}
          >
            <SliderButtons />
            {reviews?.map((review, i) => {
              return (
                <div
                  key={review.id}
                  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 justify-center items-center"
                >
                  <SwiperSlide>
                    <ReviewCard
                      img={review?.img}
                      name={review?.name}
                      country={review?.country}
                      review={review.review}
                    />
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
          <div className="flex justify-center">
          <Link
          href={"/reviews"}
          style={{
            background:
              "linear-gradient(280.91deg, #1A3183 28.64%, #16349F 77.71%)",
          }}
          className="px-8 py-3 flex items-center justify-center rounded-[5px]  w-[180px]"
        >
          <span className=" text-white font-normal fontNew">View all</span>
        </Link>
        </div>
        </div>
      
      </div>
 
      <Image
        src={"/images/reviews-vector.svg"}
        width={872}
        height={709}
        className="absolute -z-10 xl:top-14 left-0"
        alt="review"
      />
      <Image
        src={"/images/reviews-circle.svg"}
        width={150}
        height={150}
        className="absolute -z-10 bottom-20 xl:-bottom-10 right-0 xl:w-[287px] xl:h-[287px]"
        alt="reviews"
      />
      {/* <Image
        src={"/images/reviews-ellipse.svg"}
        width={113}
        height={113}
        className="absolute bottom-10 xl:-bottom-20 xl:left-[38%]"
      /> */}
    </div>
  );
};

export default Reviews;
